import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication, EventType, AuthenticationResult } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { PrimeReactProvider } from "primereact/api";
import { msalConfig } from "./authConfig";
import "primereact/resources/primereact.css";
import swDev from "./swDev";
import { initializeDB } from "./indexedDB";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authResult = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(authResult.account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </MsalProvider>
  </React.StrictMode>
);
swDev();
initializeDB();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
