export const CONSTANTS = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};

export const PLANNINGELEMENTS = {
  ROLLING_SALES_FORECAST: "Rolling Sales Forecast",
  SELL_OUT_FORECAST: "90 Days Sell Out Forecast",
  COLLECTION_PLAN: "90 Days Collection Plan",
  OPEX_PLAN: "90 Days Opex Plan",
};

export const MSALAUTHCONFIG = {
  CLIENTID: "a45deb9c-0cd3-47d8-a8e1-1155731dfd69",
  AUTHORITY: "https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78",
  REDIRECTURI: "https://commplan-np.int.bayer.com/login",
};

export const roleNameMapping: any = {
  "Business Analyst": "UL",
  "Commercial Agreement Manager": "UL",
  "Commercial Capability Manager": "UL",
  "Credit Manager": "UL",
  "Customer Service & Distribution Manager": "UL",
  "Finance Business Partner": "UL",
  "Manager - Food Chain": "UL",
  "Marketing Partner": "UL",
  "Marketing Support": "UL",
  "Seed growth (OFT)": "UL",
  "Small Holder Farming Manager": "UL",
  "State Activation Manager": "CCMC",
  "Transformation Partner": "UL",
  "UL Support": "UL",
  "Unit Lead Support": "UL",
  "Coach": "UL",
  "Product Manager": "CL",
  "Pricing Manager": "CL",
  "Anti Counterfeit Manager": "CL",
  "Customer Insights and Market reporting": "CL",
  "Agronomy Services Manager": "CL",
  "ID & Agronomy Services Manager": "CL",
  "Business Support Manager": "CL",
  "Head Office": "CL",
  "CX Go to Market": "CL",
  "CP Lead": "CL",
  "Customer Connect Manager - Marketing":"CCMC",
  "Manager - Food Chain & Seed growth (OFT)":"UL",
  "Value Creation & Crop Agronomy Expert":"UL",
  "Market Development Manager":"CL",
  "Manager Licensing & Liasoning":"CL",
  "Coordinator – Customer Interaction":"CL",
  "Manager – Indirect Tax Operations":"CL",
  "Site Services Manager":"CL"
};
