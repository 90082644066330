export default function swDev() {
  const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      //console.log("here Service Worker registered: ", registration);
    })
    .catch(error => {
      console.error("here Service Worker registration failed: ", error);
    });
}
