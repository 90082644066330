import React, { Suspense, useEffect, useRef } from "react";
import { Route, useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../constants/RouteConstants";
import { NavigateUserService } from "../../services/navigate-user-service/navigate-user.service";
import { TokenService } from "../../services/token-service/token.service";
import { Loader } from "../ui/loader/loader";

export const ProtectedRoute: React.FC<{
  component: React.FC<any>;
  path: string;
  exact?: boolean;
}> = ({ component: Component, path, exact }) => {
  const isMountRef = useRef(false);
  const history = useHistory();
  // const { instance } = useMsal();
  // const { accounts } = useMsal();

  useEffect(() => {
    // requestToken();
    // //console.log("Anmolllllllllllllllllllllllll", TokenService().getAccessToken());
    isMountRef.current = true;
    if (TokenService().getAccessToken()) {
      // //console.log("TokenService().getAccessToken()", TokenService().getAccessToken());
      // AuthService.validateToken()
      //   .then((response: any) => {
      //     if (response?.statusCode == HTTP_RESPONSE.SUCCESS) {
      //       const responseData = response.data;
      // AuthService.userInfo.next(responseData)
      history.push(new NavigateUserService().getRoute());
      // } else {
      //   history.push(ROUTE_CONSTANTS.LOGIN)
      // }
      // })
      // .catch((err: any) => {
      // //console.log("err", error);
      // })
    } else {
      history.push(ROUTE_CONSTANTS.LOGIN);
    }
    return () => {
      isMountRef.current = false;
    };
  }, []);

  // const requestToken = async () => {
  //   const isLoggedIn = instance.getAllAccounts().length > 0;
  //   if (isLoggedIn) {
  //     const response = await instance.acquireTokenSilent({
  //       ...loginRequest,
  //       account: accounts[0]
  //     })
  //     //console.log("ressssssssssssssssssssss", response);
  //     TokenService().setToken(response.accessToken, response.accessToken);
  //     history.push(new NavigateUserService().getRoute())
  //   }
  // }

  return isMountRef.current ? (
    <Route
      path={path}
      exact={exact}
      render={() => (
        <Suspense fallback={<Loader loaderHeight="calc(100vh - 70px)" />}>
          <Component />
        </Suspense>
      )}
    />
  ) : (
    <Loader />
  );
};
