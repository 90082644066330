import React, { Suspense } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/RouteConstants";
import { ProtectedRoute } from "../components/protected-routes/ProtectedRoute";
import { ErrorBoundary } from "../components/error-boundary/ErrorBoundary";
import { Loader } from "../components/ui/loader/loader";

const LoginLazyComponent = React.lazy(() => {
  return new Promise<void>(resolve => {
    resolve();
  }).then(() => import("../pages/login/Login"));
});

const LayoutLazyComponent = React.lazy(() => {
  return new Promise<void>(resolve => {
    resolve();
  }).then(() => import("../pages/layout/Layout"));
});

const LogoutLazyComponent = React.lazy(() => import("../pages/logout/Logout"));

export const RouterConfig: React.FC = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Switch>
          <Route
            exact
            path={ROUTE_CONSTANTS.LOGIN}
            render={() => (
              <Suspense fallback={<Loader loaderHeight="calc(100vh - 10px)" />}>
                <LoginLazyComponent></LoginLazyComponent>
              </Suspense>
            )}
          ></Route>
                    <Route
            exact
            path={ROUTE_CONSTANTS.LOGOUT}
            render={() => (
              <Suspense fallback={<Loader loaderHeight="calc(100vh - 10px)" />}>
                <LogoutLazyComponent></LogoutLazyComponent>
              </Suspense>
            )}
          ></Route>

          <ProtectedRoute path={ROUTE_CONSTANTS.LAYOUT} component={LayoutLazyComponent} />
          <Redirect from="*" to={ROUTE_CONSTANTS.LOGIN} />
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
