const indexDB = window.indexedDB;

const DB_NAME = 'sales_planning';
const DB_VERSION = 1;
const STORE_NAME = 'data';

export const initializeDB = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error('IndexedDB error:', event);
      reject(request.error);
    };

    request.onsuccess = () => {
      const db = request.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'key' });
      }
    };
  });
};

export const saveData = async (data: any[]) => {
  const db = await initializeDB();
  const transaction = db.transaction([STORE_NAME], 'readwrite');
  const store = transaction.objectStore(STORE_NAME);

  store.put(data);

  transaction.oncomplete = () => {
    //console.log('Data saved to IndexedDB');
  };

  transaction.onerror = (event) => {
    console.error('Error saving to IndexedDB:', event);
  };
};

export const getData = async (key: any): Promise<any[]> => {
  const db = await initializeDB();
  const transaction = db.transaction([STORE_NAME], 'readonly');
  const store = transaction.objectStore(STORE_NAME);

  return new Promise<any[]>((resolve, reject) => {
    const request = store.get(key);

    request.onsuccess = () => {
      const data = request.result;
      resolve(data);
    };

    request.onerror = (event) => {
      console.error('Error fetching data from IndexedDB:', event);
      reject(request.error);
    };
  });
};

export const clearData = async (key: any) => {
  const db = await initializeDB();
  const transaction = db.transaction([STORE_NAME], 'readwrite');
  const store = transaction.objectStore(STORE_NAME);

  const request = store.delete(key);

  transaction.oncomplete = () => {
    //console.log('Data cleared from IndexedDB');
  };

  transaction.onerror = (event) => {
    console.error('Error clearing from IndexedDB:', event);
  };

  request;
};
