import { LogLevel } from "@azure/msal-browser";
import { MSALAUTHCONFIG } from "./constants/Constants";


export const msalConfig = {
  auth: {
    clientId: MSALAUTHCONFIG.CLIENTID,
    authority: MSALAUTHCONFIG.AUTHORITY,
    redirectUri: MSALAUTHCONFIG.REDIRECTURI    
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
  grant_type: "authorization_code",
};
